<template>
    <div class="side-bar" :class="{ hidden: !expanded }">
        <div class="d-flex flex-column flex-shrink-0 p-3 h-100" :class="{ 'bg-white': !isDarkTheme, 'bg-dark': isDarkTheme}">
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item">
                    <router-link :to="{ name: 'home' }" :title="$t('Home')" class="nav-link" :class="{ active: page === 'home' }" aria-current="page">
                    <i class="fas fa-home"></i>
                    {{ $t("Home") }}
                    </router-link>

                    <router-link
                            class="nav-link"
                            :class="{ active: page === 'terms' }"
                            :title="$t('Terms of use')"
                            :to="{ name: 'terms' }"
                        >
                            <i class="fas fa-scale-balanced"></i>
                            {{ $t("Terms of use") }}
                    </router-link>

                        <router-link
                            class="nav-link"
                            :class="{ active: page === 'cookies' }"
                            :title="$t('Cookies policy')"
                            :to="{ name: 'cookies' }"
                        >
                            <i class="fas fa-cookie-bite"></i>
                            {{ $t("Cookies policy") }}
                        </router-link>

                        <router-link
                            class="nav-link"
                            :class="{ active: page === 'privacy' }"
                            :title="$t('Privacy policy')"
                            :to="{ name: 'privacy' }"
                        >
                            <i class="fas fa-shield"></i>
                            {{ $t("Privacy policy") }}
                        </router-link>

                </li>
            </ul>
            <hr>
            <div v-if="loggedIn" class="dropdown">
                <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img v-if="profileImage" class="rounded-circle me-2" width="32" height="32" :src="profileImage" />
                    <img v-else class="rounded-circle me-2" width="32" height="32" src="@/assets/user.png" />
                    <strong>{{ renderName(profileName) }}</strong>
                </a>
                <ul class="dropdown-menu text-small shadow" :class="{ 'bg-white': !isDarkTheme, 'bg-dark': isDarkTheme}" aria-labelledby="dropdownUser2">
                    <li><RouterLink :to="{ name: 'profile', params: { username: '@' + username } }" class="dropdown-item">{{ $t("My profile") }}</RouterLink></li>
                    <li><RouterLink  :to="{ name: 'account-settings' }" class="dropdown-item">{{ $t("Account settings") }}</RouterLink></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a @click="logout" class="dropdown-item">{{ $t("Log out") }}</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { getTheme } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";

export default defineComponent({
    components: {},
    name: "MenuSideBar",
    emits: ["update:expanded", "openModal"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
        };
    },
    data: function () {
        return {
            page: "",
            isDarkTheme: getTheme() === "dark",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            profileName: AuthController.ProfileName || AuthController.Username || "",
            username: AuthController.Username,
        };
    },
    methods: {
        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : "";
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
            this.username = AuthController.Username;
        },

        onThemeChanged: function () {
            this.isDarkTheme = getTheme() === "dark";
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },

        logout: function () {
            this.$emit("openModal", "logout");
        },
    },
    mounted: function () {
        this.updatePage();

        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));

        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
        this.onThemeChanged();
    },
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
});
</script>

<style>
/* Side bar */

.side-bar {
    position: absolute;
    left: 0;
    width: var(--side-bar-size);
    max-width: 100%;
    height: calc(100% - var(--top-bar-size));
    display: flex;
    flex-direction: column;
    transition:
        left 0.2s,
        width 0.2s;
    background: var(--bar-bg-color);
}

.side-bar.hidden {
    left: calc(-60px - var(--side-bar-size));
    transition:
        left 0.2s,
        visibility 0.2s;
    visibility: hidden;
}

.side-bar-overlay {
    display: none;
    position: absolute;
    background: var(--modal-overlay-bg-color);
    top: var(--top-bar-size);
    left: 0;
    height: calc(100% - var(--top-bar-size));
    width: 100%;
}

.sidebar-non-sticky .side-bar-overlay {
    display: block;
    z-index: 10;
}

.sidebar-non-sticky .side-bar {
    z-index: 11;
}

.dropdown-item {
    cursor: pointer;
}
</style>
